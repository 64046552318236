// store.js
import { createStore } from 'vuex';

const store = createStore({
  state: {
    user: null, // 存储用户信息
    isLoggedIn: false, // 用户登录状态
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
      state.isLoggedIn = user !== null;
    },
    logout(state) {
      state.user = null;
      state.isLoggedIn = false;
    },
  },
  actions: {
    login({ commit }, user) {
      // 模拟用户登录，通常会发送请求到后端验证
      commit('setUser', user);
    },
    logout({ commit }) {
      commit('logout');
    },
  },
  getters: {
    getUser: (state) => state.user,
    isLoggedIn: (state) => state.isLoggedIn,
  },
});

export default store;


// import { createStore } from 'vuex'

// export default createStore({
//   state: {
//     user:'张三'
//   },
//   getters: {
//    user(state){
//     return state.user
//    }
//   },
//   mutations: {
//     setUser(state,user){
//       state.user=user
//     }
//   },
//   actions: {
//     login({commit},user){
//       commit('setUser',user);
//     }
//   },
//   modules: {
//   }
// })
