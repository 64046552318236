<template>
  <div class="content">
    <img src="../assets/shouyelogo.jpg">
    <div class="item">
      <span>用户名:</span>
      <el-input style="width: 400px;" size="large" v-model="form.user" placeholder="请输入用户名"></el-input>
    </div>
    <div class="item"><span>密码:</span>
      <el-input style="width: 400px;" type="password" size="large" v-model="form.password" show-password
        placeholder="请输入密码">
      </el-input>
    </div>
    <div class="item">
      <el-button type="primary" @click="login(formRef)">登陆</el-button>
      <el-button type="primary" @click="display()">页面展示</el-button>
    </div>
  </div>
</template>
<script setup>
import router from '@/router';
import { reactive} from 'vue'
// import axios from 'axios'
import api  from '@/jsapi/api';
import { useStore } from 'vuex';
const store=useStore();
const form = reactive({
  user: '',
  password: '',

})
const display = () => {
  router.push('about')
};
const login = () => {

  api.post('get_twocondition_data.php', { 'table': "dy_student", 'xm': form.user, 'mm': form.password }).then(res => {
    
    if (res.length === 1) {
      
      router.push('home')
      
      store.dispatch('login',res[0])
    } else {
      console.log('密码错了')
    }

  })
};
</script>
<style scoped>
img{
  width: 480px;
}
.item {
  padding-top: 20px;
  width: 480px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}
</style>
