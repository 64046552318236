import { createRouter, createWebHashHistory } from 'vue-router'
import LoginView from '../views/LoginView.vue'

const routes = [
  {
    path: '/',
    name: 'LoginView',
    component: LoginView
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue')
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('../views/HomeView.vue'),
    children:[
      {path:'',
      component: () =>import('../views/homes/PerformanceView.vue')
    },
    {path:'Performance',
      component: () =>import('../views/homes/PerformanceView.vue')
    },
      {
      path:'TestScores',
      component: () =>import('../views/homes/TestScoresView.vue')
    },
    {
      path:'PersonalInformation',
      component: () =>import('../views/homes/PersonalInformationView.vue')
    },
    {
      path:'ClassManagement',
      component: () =>import('../views/homes/ClassManagementView.vue')
    }
    ]
  }
]
const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  base:'/',
  routes
})
export default router