import axios from 'axios';

// 创建一个 Axios 实例
const api = axios.create({
  baseURL: 'https://dy.zzdzedu.com/api/',
  // baseURL: 'http://192.168.50.18/xsgl/api/', // 设置API的基本URL
  timeout: 5000, // 设置请求超时时间
});

// 请求拦截器，可以在发送请求前进行一些操作
api.interceptors.request.use(
  (config) => {
    // 在请求头中添加一些自定义信息，比如认证信息
    // config.headers.common['Authorization'] = 'Bearer ' + getToken();
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 响应拦截器，可以在处理响应数据前进行一些操作
api.interceptors.response.use(
  (response) => {
    // 在这里可以处理响应数据，比如根据状态码进行不同的处理
    if (response.status === 200) {
      return response.data;
    }
    return response;
  },
  (error) => {
    // 在这里处理请求错误
    return Promise.reject(error);
  }
);

export default api;
